<div class="modal-content">
  <!--header-->
  <div class="modal-header">
    <button type="button" [class]="isRtl ? 'close close-left' : 'close'" (click)="close()">&times;</button>
    <h3 class="modal-title" [style.color]="storeDesignOptions.color">{{translations?.ChooseTable}}</h3>
  </div>
  <!--body-->
  <div class="modal-body modal-body-flow">
    <ng-container *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'">
      <ion-content>
        <ion-item lines="none" *ngIf="!sections">
          <ion-spinner style="margin-inline-end: 7px;zoom: 0.7;margin: 0px auto;"
            [style.color]="storeDesignOptions?.color" name="crescent"></ion-spinner>
        </ion-item>
        <ion-radio-group ngDefaultControl [(ngModel)]="selectedTable" (ionChange)="onSelect($event)">
          <ion-item *ngIf="sections" lines="none" style="--padding-start: 20px;">
            <span class="ion-margin-end">•</span>
            <ion-label translate>NoTable</ion-label>
            <ion-radio slot="end" color="success" [value]="null"></ion-radio>
          </ion-item>

          <ion-accordion-group *ngIf="sections">
            <ion-accordion *ngFor="let section of sections" [id]="section?.id" [value]="section?.id">
              <ion-item slot="header" style="--padding-start: 20px;">
                <span class="ion-margin-end">•</span>
                <ion-label>{{section?.name}}</ion-label>
              </ion-item>

              <ion-list slot="content">

                <ion-item *ngFor="let outletTable of section.outletTables" lines="none" class="ion-margin-start">
                  <ion-label>{{outletTable?.name}}</ion-label>
                  <ion-radio slot="end" color="success" [value]="outletTable">
                  </ion-radio>
                </ion-item>

              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </ion-radio-group>
      </ion-content>
    </ng-container>

    <ng-container *ngIf="currentTheme == 'merch' || currentTheme == 'shopper' || currentTheme == 'restaurants'">
      <ng-container *ngIf="!sections">
        <app-loading></app-loading>
      </ng-container>
      <ng-container *ngIf="sections">
        <form>
          <div class="flex flex-wrap">
            <div class=" w-full max-w-[240px]">
              <div class="mb-3">
                <input type="radio" [value]="null" [(ngModel)]="selectedTable" name="outletTables"
                  [id]="'outletTables0'" class="shipping sr-only" />
                <label [for]="'outletTables0'"
                  class="bg-gray-2 block cursor-pointer overflow-hidden rounded-md border border-stroke bg-white py-4 px-6 sm:px-4 md:px-6 lg:px-4 xl:px-6">
                  <span class="title mb-1 text-black block text-sm font-semibold  sm:text-base md:text-sm lg:text-base">
                    {{translations.NoTable}}
                  </span>
                </label>
              </div>
            </div>
          </div>

          <section *ngFor="let section of sections" class="accordion mb-3">
            <input type="checkbox" name="collapse" [id]="section?.id" [value]="section?.id">
            <h2 class="handle">
              <label [style.color]="storeDesignOptions?.frontColor" [style.background]="storeDesignOptions?.color"
                [for]="section?.id">{{section?.name}}</label>
            </h2>
            <div class="content">
              <div class="flex flex-wrap">

                <div *ngFor="let outletTable of section.outletTables" class=" w-full max-w-[240px]">
                  <div class="mb-3">
                    <input type="radio" [value]="outletTable" [(ngModel)]="selectedTable" name="outletTables"
                      [id]="outletTable.id" class="shipping sr-only" />
                    <label [for]="outletTable.id"
                      class="bg-gray-2 block cursor-pointer overflow-hidden rounded-md border border-stroke bg-white py-4 px-6 sm:px-4 md:px-6 lg:px-4 xl:px-6">
                      <span
                        class="title mb-1 text-black block text-sm font-semibold  sm:text-base md:text-sm lg:text-base">
                        {{outletTable?.name}}
                      </span>
                    </label>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </form>
      </ng-container>

    </ng-container>
  </div>
  <div class="modal-footer">
    <div class="fixed-btn white-background" style="display: flex; justify-content: center;">
      <button (click)="save()" class="basic-btn footer-btn" [style.background]="storeDesignOptions.color"
        [style.color]="storeDesignOptions.frontColor">
        {{translations.Save}}
      </button>
    </div>
  </div>
</div>