import { Injector } from "@angular/core";
import { StoreAppService } from "@storefront/ng.core";
import {  StoreTemplateType, StorefrontDto, StorefrontService } from "@tajer/api";
import { ionicTheme } from "src/app/theme/ionic/core/model";
import { environment } from "src/environments/environment";
import { ThemeDependencyType } from "../models/theme";
import { HtmlDocumentAppService } from "./html-document-app.service";
import { merchTheme } from "src/app/theme/merch/core/model";
import { shopperTheme } from "src/app/theme/shopper/core/model";

import { CookieAppService } from "./cookie-app.service";
import { CookieService } from "ngx-cookie";
import { StorefrontAppService } from "./storefront-app.service";
import { restaurantsTheme } from "src/app/theme/restaurants/core/model";
import { customizedIonicTheme } from "src/app/theme/basic/core/model";

/**
 * This function will be called and if we in (devlepment mode; with no ssr) will get the config
 * @param injector will be injected in AppModule
 * @returns
 */
export function initializeApp(injector: Injector) {

    const loadThemeCssDependencies = (themeCode: string) => {
        const htmlDocApp = injector.get(HtmlDocumentAppService);
        let selectedTheme;
        switch (themeCode) {
        case 'ionic':
            selectedTheme = ionicTheme;
            break;
        case 'customizedIonic':
            selectedTheme = customizedIonicTheme;
            break;
        case 'shopper':
            selectedTheme = shopperTheme;
            break;
        case 'restaurants':
            selectedTheme = restaurantsTheme;
            break;
        default:
            selectedTheme = merchTheme
            break;
        }
        selectedTheme.dependencies.filter(x => x.type === ThemeDependencyType.Style).forEach(x => {
            htmlDocApp.loadStyle(x.value);
        });
    }

    //used in multi currencies stores
    const processCurrentCurrency = (storeConfig: StorefrontDto) => {
        const cookieAppService: CookieAppService = injector.get(CookieAppService);

        if (storeConfig.currencies?.length>0) {
            if(!cookieAppService.getCurrentCurrencyId()){
                //set default currency as selected
                cookieAppService.setCurrentCurrencyId(storeConfig.currency?.id);
            }
        }else{
            //multi currencies feature is not enabled
            cookieAppService.setCurrentCurrencyId(null);
        }
    }

    const fn = () => {
        const storeAppService = injector.get(StoreAppService);        const cookieAppService: CookieAppService = injector.get(CookieAppService);
        const storefrontService = injector.get(StorefrontService);
        const cookieService: CookieService = injector.get(CookieService);
        const storefrontAppService: StorefrontAppService = injector.get(StorefrontAppService);

        //for devlepment only; with no ssr
        if (!environment.application.ssrEnabled) {
            const application = environment.application;

            const url = new URL(window.location.href);
            const currentThemeParam = url.searchParams.get('theme');
            if (environment.log) {
                console.log('----------------------');
                console.log('currentThemeParam: ' + currentThemeParam);
                console.log('----------------------');
            }

            const currentVersion = url.searchParams.get('version');
            if (environment.log) {
                console.log('----------------------');
                console.log('currentVersion: ' + currentVersion);
                console.log('----------------------');
            }
    
            //domain name
            const domainNameCookieKey = 'domainName';
            let domainName= cookieService.get(domainNameCookieKey);
            if(!domainName || domainName.length==0){
                domainName = prompt('enter domain name', '');
                if(domainName?.length>0){
                    cookieService.put(domainNameCookieKey, domainName);
                }else{
                    domainName = 'demo.' + application.defaultStoreDomain;
                    cookieService.put(domainNameCookieKey, domainName);
                }
            }

            //Token
            const tokenCookieKey = domainName + '_token';
            let token = cookieService.get(tokenCookieKey);
            if (environment.log) {
                console.log('token: ' + token);
            }
            if (token == undefined || token == 'undefined') {
                token = null;
            }

            return new Promise<void>((resolve, reject) => {
                // fetch basic store config by domain
                const basicConfigRequest = storefrontService.getBasic(
                    domainName, 
                    token,
                ).toPromise();

                basicConfigRequest.then(async (response) => {
                    if (environment.log) {
                        console.log('App basic configs loaded', response);
                    }
    
                    //set config for server
                    storeAppService.storeConfig = {...response} as StorefrontDto;
    
                    if (response.token) {
                        cookieService.put(tokenCookieKey, response.token);
                    } else {
                        cookieService.remove(tokenCookieKey);
                    }
                    cookieAppService.SetTenantId(response.tenantId);

                    //get store details config
                    let storeDetailsConfigs= await storefrontService.getInfo(
                        cookieAppService.getOutletId() != null || cookieAppService.getOutletId() !== 'undefined' ? cookieAppService.getOutletId() : null,
                        storeAppService.storeConfig?.isMarketplace,
                        null
                      ).toPromise();

                    storeAppService.storeConfig = {...storeAppService.storeConfig,...storeDetailsConfigs} as StorefrontDto;

                    //get theme info
                    let themeOptions= await storefrontService.geThemeOptions(
                        StoreTemplateType.Web,
                        currentThemeParam,
                        this.currentVersion?Number(currentVersion):null,
                      ).toPromise();
                    
                    storeAppService.storeConfig.theme = {
                        code: themeOptions.themeCode,
                        options: themeOptions.options,
                        components: []
                    }
                    //load theme css
                    loadThemeCssDependencies(storeAppService.currentTheme);

                    //get cart info
                    storefrontAppService.getCartInfo();
                    //multi currency store
                    processCurrentCurrency(storeAppService.storeConfig);
                    if (environment.log) {
                        console.log('App basic configs loaded2', storeAppService.storeConfig);
                    }
                    resolve();
                }, (error) => {
                    if (environment.log) {
                        console.log('Initalize App configs has an error!',error);
                    }
                    reject();
                });

            });
        
            //request for storefront data
            // const configRequest = storeService?.getStorefront(domainName, {
            //     pageId: "homepage",
            //     token: token,
            //     outletId: cookieAppService.getOutletId() !=null || cookieAppService.getOutletId()!== 'undefined' ? cookieAppService.getOutletId() : null
            // }).toPromise();

            // return configRequest.then(async(response) => {
            //     if (environment.log) {
            //         console.log('App configs loaded', response);
            //     }
            //     storeAppService.storeConfig = response;

            //     //get cart info 
            //     let isMarketplace= storeAppService.storeConfig.isMarketplace;
            //     if (response.token) {
            //         cookieService.put(tokenCookieKey, response.token);
            //     } else {
            //         cookieService.remove(tokenCookieKey);
            //     }
            //     cookieAppService.SetTenantId(response.tenantId);

            //     try {
            //         let cartInfo= null;

            //         if(isMarketplace){
            //             //update returned related store list and add mareketplace info to the list in order to use them later to get the info
            //             response.relatedStores.push({
            //                 tenantId: response.tenantId,
            //                 storeId: response.id,
            //                 outletId: response.outlet.id,
            //                 averageRatings: null,
            //                 saleType: null
            //             });
                        
            //             let storedMarketTenant=cookieAppService.getMarketTenantId();
            //             let marketTenant=storedMarketTenant?storedMarketTenant: response.tenantId
            //             cartInfo= await cartAppService.getMyCartMarketplace(marketTenant);
            //             //if cartInfo is null or empty (no shopping cart) then return the stored tenant to current store tenant
            //             if(!cartInfo || cartInfo.cartLines?.length==0){
            //                 cookieAppService.SetMarketTenantId(response.tenantId);
            //             }
            //         }else{
            //             cartInfo= await cartAppService.getMyCart();
            //         }
            //         cartAppService.shoppingCart= cartInfo;
            //     } catch (error) {
            //         if(isMarketplace){
            //             cookieAppService.SetMarketTenantId(response.tenantId);
            //         }
            //     }
                
            //     loadThemeCssDependencies(storeAppService.currentTheme);

            //     //multi currency store
            //     processCurrentCurrency(response);
            // }, () => {
            //     if (environment.log) {
            //         console.log('App configs has an error!');
            //     }
            // });
        }else{
            return Promise.resolve();
        }
    };
    return fn;
}