import { Injector, Optional } from '@angular/core';
import { HtmlDocumentAppService } from '../app-services/html-document-app.service';
import { CurrencyStoreDto, CustomerSummaryStoreDto, ExternalServiceSettingsDto, OutletStoreDto, SalesChannels, StoreConfigFeaturesDto, StoreOptionsDto, StoreStatus, StorefrontCustomerServiceDto, StorefrontRelatedStoresDto, StorefrontSEODto, StorefrontSocialMediaDto } from '@tajer/api';
import { TranslateAppService } from '../app-services/translate-app.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformAppService, StoreAppService } from '@storefront/ng.core';
import { ServerService } from '../app-services/server.service';
import { SegmentService } from '../app-services/segment.service';
import { EntityUtilityService } from '../app-services/entity-utility.service';
import { OrderAppService } from '../app-services/order-app.service';
import { CurrencyAppService } from '../app-services/currency-app.service';
import { CustomerAppService } from '../app-services/customer-app.service';
import { CartAppService } from '../app-services/cart-app.service';
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { DeliveryAppService } from '../app-services/delivery-app.service';
import { ToastrService } from 'ngx-toastr';
import { PluginRegistryService } from 'src/app/plugins/core';
import { DrawerAppService } from '../app-services/drawer-app.service';
import { CustomStorePageStoreDto } from '@storefront/ng.themes/lib/models';
import { CookieAppService } from '../app-services/cookie-app.service';

export class BaseComponent {

  //#region variables
  protected trsnalteApp: TranslateAppService;
  protected htmlDocApp: HtmlDocumentAppService;
  protected platformApp: PlatformAppService;
  private route: ActivatedRoute;
  public router: Router;
  public serverService: ServerService;
  public storeAppService: StoreAppService
  public segmentService: SegmentService;
  public entityUtilityService: EntityUtilityService;
  public orderAppService: OrderAppService;
  public currencyAppService: CurrencyAppService;
  public customerAppService: CustomerAppService;
  public cartAppService: CartAppService;
  public deliveryAppService: DeliveryAppService;
  public modal: Modal;
  public toastr: ToastrService;
  public pluginRegistry: PluginRegistryService;
  public drawerAppService: DrawerAppService;
  public cookieAppService: CookieAppService;
  
  /**
   * server only code or browser only if run in development.
   * You need to set ssrEnabled to false
   */
  protected get isServer(): boolean {
    return this.platformApp.isServer || (!environment.production && !environment.application.ssrEnabled);
  }
  protected get isBrowser(): boolean {
    return !this.platformApp.isServer;
  }

  //#endregion

  constructor(
    injector: Injector,
  ) {
    this.trsnalteApp = injector.get(TranslateAppService);
    this.htmlDocApp = injector.get(HtmlDocumentAppService);
    this.platformApp = injector.get(PlatformAppService);
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.storeAppService = injector.get(StoreAppService);
    this.segmentService = injector.get(SegmentService);
    this.entityUtilityService = injector.get(EntityUtilityService);
    this.orderAppService = injector.get(OrderAppService);
    this.currencyAppService = injector.get(CurrencyAppService);
    this.customerAppService = injector.get(CustomerAppService);
    this.cartAppService = injector.get(CartAppService);
    this.deliveryAppService = injector.get(DeliveryAppService);
    this.toastr = injector.get(ToastrService);
    this.pluginRegistry = injector.get(PluginRegistryService);
    this.drawerAppService = injector.get(DrawerAppService);
    this.cookieAppService = injector.get(CookieAppService);

    if(this.isBrowser)
    this.modal= injector.get(Modal);

    if (environment.application.ssrEnabled) {
      this.serverService = injector.get(ServerService);
    }
    // const themeParam = this.route.snapshot.queryParams['theme'];
    // if (themeParam) {
    //   this.currentTheme = themeParam;
    // }
  }

  getUrl(url: any) {
    return this.trsnalteApp.getUrl(url);
  }

  public get currentLang(): string {
    return this.trsnalteApp.currentLang;
  }

  public get isRtl(): boolean | undefined {
    return this.trsnalteApp.isRtl;
  }
  public get dir(): string {
    return this.trsnalteApp.dir;
  }

  public get storeOptions(): StoreOptionsDto {
    return this.storeAppService.storeOptions;
  }

  public get configFeatures(): StoreConfigFeaturesDto {
    return this.storeAppService?.configFeatures;
  }

  //all translations
  get translations() {
    return this.trsnalteApp.translations;
  }

  get storeDesignOptions(): any {
    return this.storeAppService?.storeConfig?.theme?.options;
  }

  get currentOutlet(): OutletStoreDto {
    return this.storeAppService?.currentOutlet;
  }

  public get clientLogoUrl(): string {
    return this.storeAppService?.clientLogoUrl;
  }

  public get defaultImage(): string {
    return this.storeAppService?.defaultImage;
  }

  public get customerServiceInfo(): StorefrontCustomerServiceDto {
    return this.storeAppService?.customerServiceInfo;
  }

  public get socialMediaInfo(): StorefrontSocialMediaDto {
    return this.storeAppService?.socialMediaInfo;
  }


  get storeDesignPageComponents(): any {
    return this.storeAppService?.storeConfig?.theme?.components;
  }

  public get storeSeoInfo(): StorefrontSEODto {
    return this.storeAppService.storeConfig?.seo;
  }

  get currentTheme(): string {
    return this.storeAppService?.currentTheme;
  }

  set currentTheme(themCode: string) {
    this.storeAppService.currentTheme = themCode;
  }

  //getRequestFullUrl
  get getRequestFullUrl(){
    let fullRequestUrl= '';
    if(!this.isBrowser){
      fullRequestUrl = `${this.serverService?.getRequestHostUrl()}${this.router.url}` ;
    }
    else{
      var root = location.protocol + '//' + location.host;
      fullRequestUrl= `${root}${this.router.url}` ;
    }
    return fullRequestUrl;
  }

  getRequestHostUrl(){
    let requestHostUrl= '';
    if(!this.isBrowser){
      requestHostUrl=this.serverService?.getRequestHostUrl();
    }
    else{
      var root = location.protocol + '//' + location.host;
      requestHostUrl=root;
    }
    return requestHostUrl;
  }

  protected getPageTitle(pageTitleKey = null, subtitel = null) {
    var title = this.storeAppService.storeName;

    if (pageTitleKey) {
        title += ' - ' + this.trsnalteApp.translate(pageTitleKey);
    }
    if (subtitel) {
        title += subtitel;
    }
    return title;
  }

  public get externalServices(): ExternalServiceSettingsDto {
    return this.storeAppService.storeConfig?.externalServices;
  }

  public get isFacebookPixelEnabled(): boolean {
    return !!this.storeAppService?.storeConfig?.externalServices?.facebookPixelId;
  } 

  public get isSnapPixelEnabled(): boolean {
    return !!this.storeAppService.storeConfig?.externalServices?.snapChatId;
  }

  public get isGoogleAnalyticEnabled(): boolean {
    return !!this.storeAppService.storeConfig?.externalServices?.googleAnalyticId;
  }

  public get isSegmentEnabled(): boolean {
    return this.segmentService.isSegmentEnabled;
  }
  

  getHtmlTargetValue($event) {
    return (<HTMLInputElement>$event.target)?.value;
  }

  /**
       * get property value depend on the current language
       * @param entity any entity
       * @param valueKey property name
       */
  getValue(entity: any, valueKey: string = 'name'): string {
    return this.entityUtilityService.getEntityValue(entity, valueKey);
  }

  getDuration(duration: number, viewLabel = true) {
    const days = Math.floor(duration / 60 / 24);
    const hours = Math.floor(duration / 60) % 24;
    const minutes = duration % 60;

    let time = '';
    if (days > 0) { time += (days + (viewLabel ? ' ' + this.trsnalteApp.translate('Days') : '')); }
    if (days > 0 && hours > 0) { time += ', '; }
    if (hours > 0) { time += (hours + (viewLabel ? ' ' + this.trsnalteApp.translate('Hours') : '')); }
    if (minutes > 0 && hours > 0) { time += ', '; }
    if (minutes > 0) { time += (minutes + (viewLabel ? ' ' + this.trsnalteApp.translate('Minutes') : '')); }
    return time;
  }

  addFacebookPixelContactEvent() {
    this.pluginRegistry.trigger('implementContactEvent');
  }

  //#region currency
  public get isCurrnetAfter(): boolean {
    return this.currencyAppService.isCurrnetAfter;
  }

  public get isCurrnetBefore(): boolean {
    return this.currencyAppService.isCurrnetBefore;
  }

  public get currnetSymbol(): string {
    return this.entityUtilityService.getlocalSymbol(this.currnetCurrnecy);
  }

  public get currencyFractionDigit(): number {
    return this.currnetCurrnecy?.fractionDigit;
  }

  displayCurrency(value: number): string {
    return this.isBrowser ? this.currencyAppService.format(value, this.currencyFractionDigit) : value?.toString();
  }

  public get currnetCurrnecy(): CurrencyStoreDto {
    return this.currencyAppService.currnetCurrnecy;
  }

  public set currnetCurrnecyId(currencyId: string){
    this.currencyAppService.currnetCurrnecyId=currencyId;
  }

  public get storeCurrencies(): CurrencyStoreDto[] {
    return this.currencyAppService.storeCurrencies;
  }

  public get multiCurrenciesEnabled(): boolean {
    return this.currencyAppService.multiCurrenciesEnabled;
  }

  public get requestCurrencyId(): string {
    return this.currencyAppService.requestCurrencyId;
  }

  //#endregion

  public get menuItems(): CustomStorePageStoreDto[] {
    let menuItems: CustomStorePageStoreDto[] = [];

    // if (this.storeDesignOptions?.showHomeOnMenuHeader && !this.isPlatformHybrid) {
    if (this.storeDesignOptions?.showHomeOnMenuHeader) {
      menuItems.push(...[
        {
          id: '1',
          name: this.trsnalteApp.translate('Home'),
          url: this.getUrl('/'),
          icon: 'home'
        } as CustomStorePageStoreDto
      ]);
    }
    //if this is marketplace then show stores menu
    if (this.isMarketplace) {
      menuItems.push(...[
        {
          id: '3',
          name: this.trsnalteApp.translate('Stores'),
          url: this.getUrl('/stores'),
          icon: 'grid'
        } as CustomStorePageStoreDto
      ]);
    }
    if (this.storeDesignOptions?.showAllProductsOnMenuHeader) {
      menuItems.push(...[
        {
          id: '2',
          name: this.trsnalteApp.translate('AllProducts'),
          url: this.getUrl('/catalog'),
          icon: 'grid'
        } as CustomStorePageStoreDto
      ]);
    }
    menuItems.push(...this.storeAppService.menuCategories.slice(0, 9).map(c => {
      return {
        id: c.id,
        name: this.getValue(c),
        url: this.entityUtilityService.getCategoryUrl(c),
        icon: 'pricetag'
      } as CustomStorePageStoreDto;
    }));

    //if (this.storeDesignOptions?.showAllCategoriesOnMenuHeader && this.storeAppService.isStoreHasCategories) {
    if (this.storeDesignOptions?.showAllCategoriesOnMenuHeader && this.storeAppService.isStoreHasCategories) {
      menuItems.push(...[
        {
          id: '4',
          name: this.trsnalteApp.translate('AllCategories'),
          url: this.getUrl('/categories'),
          icon: 'pricetags'
        } as CustomStorePageStoreDto
      ]);
    }
    //if (this.storeAppService.isStoreHasBrands && this.storeDesignOptions?.showAllBrandsOnMenuHeader) {
    if (this.storeAppService.isStoreHasBrands && this.storeDesignOptions?.showAllBrandsOnMenuHeader) {
      menuItems.push(...[
        {
          id: '5',
          name: this.trsnalteApp.translate('AllBrands'),
          url: this.getUrl('/brands'),
          icon: 'ribbon'
        } as CustomStorePageStoreDto
      ]);
    }
    if (this.storeOptions?.isStoreBlogEnabled && this.storeDesignOptions?.showStoreBlogOnMenuHeader) {
      menuItems.push(...[
        {
          id: '6',
          name: this.trsnalteApp.translate('StoreBlog'),
          url: this.getUrl('/blog'),
          icon: 'library'
        } as CustomStorePageStoreDto
      ]);
    }
    if (this.storeDesignOptions?.showMyOrdersOnMenuHeader) {
      menuItems.push(...[
        {
          id: '7',
          name: this.trsnalteApp.translate('MyOrders'),
          url: this.getUrl('/orders'),
          icon: 'cart'
        } as CustomStorePageStoreDto
      ]);
    }
    if (this.storeAppService.isMultiOutletEnabled && this.storeDesignOptions?.showOurOutletsOnMenuHeader) {
      menuItems.push(...[
        {
          id: '8',
          name: this.trsnalteApp.translate('OurOutlets'),
          url: this.getUrl('/outlets'),
          icon: 'storefront'
        } as CustomStorePageStoreDto,
      ]);
    }
    if (this.storeDesignOptions?.showContactUsOnHeader) {
      menuItems.push(
        ...[
          {
            id: '9',
            name: this.trsnalteApp.translate('ContactUs'),
            url: this.getUrl('/contact'),
            icon: 'call-outline'
          } as CustomStorePageStoreDto,
        ]
      );
    }

    //add Menupages and blogsPages
    menuItems.push(...this.storeAppService.menuPages.map(page => {
      return {
        id: page.id,
        name: this.getValue(page),
        url: this.getUrl('/page/' + page.slug),
        icon: 'logo-web-component'
      } as CustomStorePageStoreDto;
    }));
    menuItems.push(...this.storeAppService.menuBlogPages.map(page => {
      return {
        id: page.id,
        name: this.getValue(page),
        url: this.getUrl('/blog/' + page.slug),
        icon: 'reader'
      } as CustomStorePageStoreDto;
    }));
    return menuItems;

  }

  navigateToNotFound() {
    this.router.navigate([this.getUrl('not-found')]);
  }

  navigateToHome() {
    this.router.navigate([this.getUrl('/')]);
  }

  public get currentCustomer(): CustomerSummaryStoreDto {
    return this.customerAppService?.customer;
  }

  public get providerName(): string {
    let name = environment?.provider?.name;
    if (this.trsnalteApp.currentLang == 'ar') {
        name = environment?.provider?.nameAr;
    }
    return name;
  }

  public get providerLogo(): string {
      return environment?.provider?.logo;
  }

  public get providerRegistrationUrl(): string {
      return environment?.provider?.registrationUrl;
  }

  public get providerWebsite(): string {
    let websiteUrl: string = environment?.provider?.website;
    if (this.trsnalteApp.currentLang == 'ar') {
        websiteUrl += '/ar';
    }
    return websiteUrl;
  }

  //alerts
  async openAddToFavoriteAlert() {
    const dialogRef = this.modal
      .confirm()
      .cancelBtn(this.trsnalteApp.translate('Close'))
      .cancelBtnClass('modal-ok-btn')
      .okBtn(this.trsnalteApp.translate('Login'))
      .okBtnClass('modal-ok-btn')
      .isBlocking(true)
      .showClose(false)
      .headerClass('d-none')
      .body(this.trsnalteApp.translate(this.trsnalteApp.getTranslateKey('AddToFavoriteAlertMessage')))
      .dialogClass("modal-x-sm")
      .open();

    dialogRef.result.then(
      (result) => {
        if (result) {
          this.router.navigate([this.getUrl('/login')]);
        }
      },
      () => {
        return;
      }
    );
    if(this.isBrowser){
      document.body.classList.remove("modal-open");
    } 
  }

  async successAlert(message) {
    const dialogRef = this.modal
      .alert()
      .okBtn(this.trsnalteApp.translate('Ok'))
      .okBtnClass('modal-ok-btn')
      .isBlocking(true)
      .showClose(false)
      .headerClass('d-none')
      .body(message)
      .dialogClass("modal-x-sm")
      .open();

    dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  showNotify(messageText: string) {
    this.toastr.success(messageText,'',{});
  }

  showError(error) {
    if (error?.error?.error) {
      this.toastr.error(error?.error?.error.message, '', {});
    }
  }

  //#region marketplace
  getRelatedStore(tenantId: string): StorefrontRelatedStoresDto{
    return this.storeAppService.storeConfig.relatedStores?.find(s=>s.tenantId==tenantId);
  }

  getMarketplaceCartOutletId(tenantId: string){
    return this.storeAppService.storeConfig.relatedStores?.find(s => s.tenantId == tenantId)?.outletId;
  }

  get isMarketplace(){
    return this.storeAppService.storeConfig.isMarketplace;
  } 
  //#endregion

  onRefresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
  }
  
  get getSalesChannel() {
    return SalesChannels.WebStore;
  }

  get downPaymentPercentageOfTheTotal() {
    let maxItemDownPaymentPercentage = 0;
    this.cartAppService?.shoppingCart?.cartLines?.forEach(cartLine => {
      if (cartLine.item?.downPaymentPercentage > maxItemDownPaymentPercentage) {
        maxItemDownPaymentPercentage = cartLine.item?.downPaymentPercentage;
      }
    });

    //commented as will be removed
    // if (this.currentOutlet?.downPaymentPercentageOfTheTotal > maxItemDownPaymentPercentage) {
    //   maxItemDownPaymentPercentage = this.currentOutlet?.downPaymentPercentageOfTheTotal;
    // }
    return maxItemDownPaymentPercentage;
  }

  get storeUnderMaintenance() {
    return !this.storeAppService?.storeConfig || this.storeAppService?.storeConfig?.status== StoreStatus.UnderMaintenance;
  }

  get storeActive() {
    return this.storeAppService?.storeConfig?.status== StoreStatus.Active;
  }

  scorllToTop(){
    if(this.isBrowser){
      if(this.currentTheme == "ionic" || this.currentTheme == "customizedIonic"){
        const content: any = document.getElementById('ion-main-content');
        content.scrollToTop(0);
      }else{
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }

}


