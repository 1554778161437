import { Component, Injector, Input, Optional } from '@angular/core';
import { AddressDto, AddressService, CustomerService } from '@tajer/api';
import {
  AddressBookConfiguration,
  AddressBookDesignOptions,
} from '@storefront/ng.themes/lib/themes/default/default-address-book-card/models';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { DialogRef } from 'ngx-modialog-7';
import { BSModalContext, Modal } from 'ngx-modialog-7/plugins/bootstrap';

@Component({
  selector: 'app-address-book-card',
  templateUrl: './address-book-card.component.html',
  styleUrls: ['./address-book-card.component.scss'],
})
export class AddressBookCardComponent extends BaseComponent {
  //#region variables
  desginOptions: AddressBookDesignOptions;
  configuration: AddressBookConfiguration;
  @Input() viewActions: boolean = true;
  @Input() isModal: boolean = false;
  @Input() addresses: AddressDto[];
  @Input() loading: boolean;
  //#endregion

  constructor(injector: Injector,
    @Optional() public dialog: DialogRef<AddressBookCardComponentContext>,
    private customerService: CustomerService,
    private addressService: AddressService) {
    super(injector);
  }

  ngOnInit() {
    this.setIonicThemeOptions();
    this.setModalContext();
  }

  setIonicThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions?.color,
    };
    this.configuration = {
      addressFormUrl: this.getUrl('/address-form/new').toString(),
    };
  }

  async delete(id: string) {
    const dialogRef = this.modal
      .confirm()
      .title(this.trsnalteApp.translate('ConfirmDeleteTitle'))
      .cancelBtn(this.trsnalteApp.translate('Cancel'))
      .cancelBtnClass('modal-ok-btn')
      .okBtn(this.trsnalteApp.translate('Delete'))
      .okBtnClass('modal-ok-btn')
      .isBlocking(true)
      .showClose(false)
      .body(this.trsnalteApp.translate('ConfirmDeleteMessage'))
      .dialogClass("modal-x-sm")
      .open();

    dialogRef.result.then(
      (result) => {
        if (result) {
          this.addressService.delete(id).toPromise().then(async (response) => {
            this.showNotify(this.trsnalteApp.translate('SavedSuccessfully'));
            this.addresses = this.addresses.filter(address => address.id !== id);
          }, (error) => {
            this.showError(error)
          });
        }
      },
      () => {
        return;
      }
    );
  }

  setDefault(id: string) {
    this.customerService.putCustomerDefaultAddress(this.currentCustomer.id, { addressId: id }).toPromise().then(async (response) => {
      this.showNotify(this.trsnalteApp.translate('SavedSuccessfully'));
    }, (error) => {
      console.log(error);
    });
  }

  dismiss(address: AddressDto) {
    if (this.isModal) {
      this.dialog.close(address);
      if(this.isBrowser){
        document.body.classList.remove("modal-open");
      }
    }
  }

  setModalContext() {
    if (this.dialog) {
      this.isModal = this.dialog?.context.isAddressBookCardModal;
      this.viewActions = this.dialog?.context.viewActions
      this.addresses = this.dialog?.context.addresses
      this.loading = this.dialog?.context.loading
    }
  }

}

export class AddressBookCardComponentContext extends BSModalContext {
  constructor() {
    super();
  }

  isAddressBookCardModal: boolean;
  viewActions: boolean;
  addresses: AddressDto[];
  loading: boolean;
}

