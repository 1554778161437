import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryAddressCardComponent } from './delivery-address-card.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AddressCardModule } from '../address-card/address-card.module';
import { AddressFormModule } from '../address-form/address-form.module';
import { DefaultDeliveryAddressCardModule } from '@storefront/ng.themes';
import { ModalModule } from 'ngx-modialog-7';
import { BootstrapModalModule } from 'ngx-modialog-7/plugins/bootstrap';

@NgModule({
  declarations: [DeliveryAddressCardComponent],
  imports: [
    CommonModule,
    SharedModule,
    AddressCardModule,
    AddressFormModule,
    DefaultDeliveryAddressCardModule,
    ModalModule.forRoot(),
    BootstrapModalModule
  ],
  exports: [DeliveryAddressCardComponent],
})
export class DeliveryAddressCardModule {}
