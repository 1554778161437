<div class="modal-content">
  <!--header-->
  <div class="modal-header">
    <button type="button" [class]="isRtl ? 'close close-left' : 'close'" (click)="close()">&times;</button>
    <h3 class="modal-title" [style.color]="storeDesignOptions.color">
      <span *ngIf="deliveryAppService.currentDeliveryType == deliveryType.SelfPickup" translate>PickupTime</span>
      <span *ngIf="deliveryAppService.currentDeliveryType == deliveryType.Delivery" translate>DeliveryTime</span>
      <span *ngIf="deliveryAppService.currentDeliveryType == deliveryType.DineIn" translate>DineInTime</span>
    </h3>
  </div>
  <!--body-->
  <div class="modal-body" [class.modal-body-flow]="currentTheme=='ionic' || currentTheme == 'customizedIonic'">
    <ng-container *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'">
      <ion-row>
        <ion-col size="11" class="ion-margin-horizontal">
          <p *ngIf="deliveryAppService.currentDeliveryType == deliveryType.SelfPickup" translate>SchedulePickupOrderNote
          </p>
          <p *ngIf="deliveryAppService.currentDeliveryType == deliveryType.Delivery" translate>ScheduleDeliveryOrderNote
          </p>
          <p *ngIf="deliveryAppService.currentDeliveryType == deliveryType.DineIn" translate>ScheduleDineInOrderNote</p>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="header && currentOutlet.availableForLiveOrders" class="ion-margin-horizontal">
        <ion-col>
          <ion-radio-group ngDefaultControl [value]="isOpenCalendar" (ionChange)="onSelect($event)">
            <ion-item lines="none">
              <ion-radio slot="start" color="success" [value]="false" class="ion-margin-end"></ion-radio>
              <ion-label translate>ASAP</ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-radio slot="start" color="success" [value]="true" class="ion-margin-end"></ion-radio>
              <ion-label translate>ScheduleOrder</ion-label>
            </ion-item>
          </ion-radio-group>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="isOpenCalendar">
        <ion-col [size]="screenBreakpointsService.isSizeMdOrUp?'8':'12'">
          <ion-datetime presentation="date" [locale]="trsnalteApp.currentLang" class="date-picker"
            [min]="today?.toISOString()" [value]="selectedDate" (ionChange)="changeDate($event)">
            <span slot="time-label" translate>Time</span>
          </ion-datetime>

        </ion-col>
        <ion-col [size]="screenBreakpointsService.isSizeMdOrUp?'4':'12'">
          <ion-datetime [min]="today?.toISOString()" [locale]="trsnalteApp.currentLang" [value]="selectedDate"
            presentation="time" (ionChange)="changeDate($event)"></ion-datetime>

        </ion-col>
      </ion-row>
    </ng-container>

    <ng-container *ngIf="currentTheme == 'merch' || currentTheme == 'shopper' || currentTheme == 'restaurants'">
      <div class="mb-2">
        <p *ngIf="deliveryAppService.currentDeliveryType == deliveryType.SelfPickup" translate>SchedulePickupOrderNote
        </p>
        <p *ngIf="deliveryAppService.currentDeliveryType == deliveryType.Delivery" translate>ScheduleDeliveryOrderNote
        </p>
        <p *ngIf="deliveryAppService.currentDeliveryType == deliveryType.DineIn" translate>ScheduleDineInOrderNote</p>
      </div>

      <div *ngIf="header && currentOutlet.availableForLiveOrders" class="mb-2 w-full">
        <div class="inline-flex rounded-lg">
          <button (click)="isOpenCalendar=false; onSelect(isOpenCalendar)"
            [style.color]="isOpenCalendar==false?storeDesignOptions.frontColor:null"
            [style.background]="isOpenCalendar==false?storeDesignOptions.color:null"
            class="border-light inline-flex items-center justify-center rounded-l-lg border py-[10px] px-[12px] text-center text-base font-semibold text-black transition-all sm:py-3 sm:px-6">
            <span class="pr-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
              </svg>
            </span>
            {{translations.ASAP}}
          </button>
          <button (click)="isOpenCalendar=true; onSelect(isOpenCalendar)"
            [style.background]="isOpenCalendar==true?storeDesignOptions.color:null"
            [style.color]="isOpenCalendar==true?storeDesignOptions.frontColor:null"
            class="border-light inline-flex items-center justify-center rounded-r-lg border py-[10px] px-[12px] text-center text-sm font-semibold text-black transition-all sm:py-3 sm:px-6 sm:text-base">
            <span class="pr-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
            {{translations.ScheduleOrder}}
          </button>
        </div>
      </div>

      <div *ngIf="isOpenCalendar" class="datetimepicker" [style.border-color]="storeDesignOptions.color">
        <input type="datetime-local" id="date" (ngModelChange)="changeDate($event)" [ngModel]="selectedDate"
          onfocus="this.min=new Date().toISOString().split('T')[0]">
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <div *ngIf="wrongDate" class="alert" style="text-align: center;">
      <div style="color: red">
        {{translations.WrongDateMessage}}
      </div>
    </div>
    <div class="fixed-btn white-background" style="display: flex; justify-content: center;">
      <button (click)="save()" class="basic-btn footer-btn" [style.background]="storeDesignOptions.color"
        [style.color]="storeDesignOptions.frontColor">
        {{translations.Save}}
      </button>
    </div>
  </div>
</div>