<storefront-ionic-item-wide-card *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'" [desginOptions]="desginOptions"
  [translations]="translations" [configuration]="configuration" [item]="item"
  [itemQuantity]="cartAppService.getItemQuantity(item?.id)" [isCurrnetBefore]="isCurrnetBefore"
  [isCurrnetAfter]="isCurrnetAfter" [currnetSymbol]="currnetSymbol" [itemPrice]="displayCurrency(item?.price)"
  [itemOldPrice]="displayCurrency(item?.oldPrice)" [itemPreTaxPrice]="displayCurrency(item?.preTaxPrice)"
  [itemPostTaxPrice]="displayCurrency(item?.postTaxPrice)" [isEdit]="isEdit" [isView]="isView"
  [showVariant]="showVariant" [(quantity)]="quantity" [price]="price" [oldPriceUpdated]="oldPriceUpdated"
  [displayOldPriceUpdated]="displayCurrency(oldPriceUpdated)" [total]="total" [displayTotal]="displayCurrency(total)"
  [description]="description" [modifiers]="modifiers" [cartLineId]="cartLineId" [addingToCart]="addingToCart"
  [added]="added"
  [addOrRemoveFavoriteLoading]="favoriteAppService.addOrRemoveFavoriteLoading && favoriteAppService.addOrRemoveFavoriteItemId == item.id"
  [isItemHasMultiVariants]="item?.hasVariants" [displayCurrencyFunc]="displayCurrency.bind(this)"
  [viewAddToCart]="viewAddToCart" [viewQuantity]="viewQuantity" [viewRemoveBtn]="viewRemoveBtn" [variants]="variants"
  [isOutOfStock]="item?.isOutOfStock" [isFavorite]="isFavorite" [favoriteItemId]="favoriteItemId" [images]="images"
  [priceRange]="priceRange" [priceRangeMin]="displayCurrency(priceRange?.min)"
  [priceRangeMax]="displayCurrency(priceRange?.max)" [isSideMenuCart]="isSideMenuCart"
  [loadingRemove]="orderAppService.loadingRemove && orderAppService.removedItemId === cartLineId"
  [increaseQuantityLoading]="orderAppService.increaseQuantityLoading && orderAppService.increaseQuantityItemId === cartLineId"
  [decreaseQuantityLoading]="orderAppService.decreaseQuantityLoading && orderAppService.decreaseQuantityItemId === cartLineId"
  [itemShortDescription]="itemShortDescription" [cardHeight]="cardHeight"
  [offerPercentage]="offerPercentage"
  [isRtl]="isRtl" (addEvents)="addEvents()" (addOrRemoveFavorite)="addOrRemoveFavorite()" (addToCart)="addToCart()"
  (changeImg)="changeImg()" (setFirstImg)="setFirstImg()" (increaseQuantity)="increaseQuantity()"
  (decreaseQuantity)="decreaseQuantity()" (onUpdateQuantity)="onUpdateQuantity($event)"
  (removeClicked)="removeClicked.emit()">

</storefront-ionic-item-wide-card>

<!-- Merch Item-wide-card starts -->
<lib-merch-item-wide-card *ngIf="currentTheme == 'merch' || currentTheme == 'shopper'"
  [merchDesginOptions]="merchDesginOptions" [merchConfiguration]="merchConfiguration" [translations]="translations"
  [item]="item" [isRtl]="isRtl" [itemQuantity]="cartAppService.getItemQuantity(item?.id)"
  [isCurrnetBefore]="isCurrnetBefore" [isCurrnetAfter]="isCurrnetAfter" [currnetSymbol]="currnetSymbol"
  [itemPrice]="displayCurrency(item?.price)" [itemOldPrice]="displayCurrency(item?.oldPrice)"
  [itemPreTaxPrice]="displayCurrency(item?.preTaxPrice)" [itemPostTaxPrice]="displayCurrency(item?.postTaxPrice)"
  [isEdit]="isEdit" [isView]="isView" [showVariant]="showVariant" [quantity]="quantity"
  [oldPriceUpdated]="oldPriceUpdated" [displayOldPriceUpdated]="displayCurrency(oldPriceUpdated)"
  [modifiers]="modifiers" [added]="added" [addingToCart]="addingToCart"
  [addOrRemoveFavoriteLoading]="favoriteAppService.addOrRemoveFavoriteLoading && favoriteAppService.addOrRemoveFavoriteItemId == item.id"
  [isItemHasMultiVariants]="item?.hasVariants" [displayCurrencyFunc]="displayCurrency.bind(this)"
  [viewAddToCart]="viewAddToCart" [viewQuantity]="viewQuantity" [viewRemoveBtn]="viewRemoveBtn" [variants]="variants"
  [isOutOfStock]="item?.isOutOfStock" [isFavorite]="isFavorite" [priceRange]="priceRange"
  [priceRangeMin]="displayCurrency(priceRange?.min)" [priceRangeMax]="displayCurrency(priceRange?.max)"
  [isSideMenuCart]="isSideMenuCart" [itemShortDescription]="itemShortDescription" [cardHeight]="cardHeight"
  [loadingRemove]="orderAppService.loadingRemove && orderAppService.removedItemId === cartLineId"
  (addEvents)="addEvents()" (addOrRemoveFavorite)="addOrRemoveFavorite()" (addToCart)="addToCart()"
  (changeImg)="changeImg()" (setFirstImg)="setFirstImg()" (removeClicked)="removeClicked.emit()">
</lib-merch-item-wide-card>

<!-- Restaurants Item-wide-card starts -->
<lib-restaurants-item-wide-card *ngIf="currentTheme == 'restaurants'"
  [restaurantsDesginOptions]="restaurantsDesginOptions"
  [restaurantsConfiguration]="restaurantsConfiguration"
  [translations]="translations"
  [item]="item"
  [isRtl]="isRtl"
  [itemQuantity]="cartAppService.getItemQuantity(item?.id)"
  [isCurrnetBefore]="isCurrnetBefore"
  [isCurrnetAfter]="isCurrnetAfter"
  [currnetSymbol]="currnetSymbol"
  [itemPrice]="displayCurrency(item?.price)"
  [itemOldPrice]="displayCurrency(item?.oldPrice)"
  [itemPreTaxPrice]="displayCurrency(item?.preTaxPrice)"
  [itemPostTaxPrice]="displayCurrency(item?.postTaxPrice)"
  [isEdit]="isEdit"
  [isView]="isView"
  [showVariant]="showVariant"
  [quantity]="quantity"
  [oldPriceUpdated]="oldPriceUpdated"
  [displayOldPriceUpdated]="displayCurrency(oldPriceUpdated)"
  [hideDefaultItemImage]="hideDefaultItemImage"
  [modifiers]="modifiers"
  [added]="added"
  [addingToCart]="addingToCart"
  [addOrRemoveFavoriteLoading]="favoriteAppService.addOrRemoveFavoriteLoading && favoriteAppService.addOrRemoveFavoriteItemId == item.id"
  [isItemHasMultiVariants]="item?.hasVariants"
  [displayCurrencyFunc]="displayCurrency.bind(this)"
  [viewAddToCart]="viewAddToCart"
  [viewQuantity]="viewQuantity"
  [viewRemoveBtn]="viewRemoveBtn"
  [variants]="variants"
  [isOutOfStock]="item?.isOutOfStock"
  [isFavorite]="isFavorite"
  [priceRange]="priceRange"
  [priceRangeMin]="displayCurrency(priceRange?.min)"
  [priceRangeMax]="displayCurrency(priceRange?.max)"
  [isSideMenuCart]="isSideMenuCart"
  [itemShortDescription]="itemShortDescription"
  [cardHeight]="cardHeight"
  [loadingRemove]="orderAppService.loadingRemove && orderAppService.removedItemId === cartLineId"
  (addEvents)="addEvents()"
  (addOrRemoveFavorite)="addOrRemoveFavorite()"
  (addToCart)="addToCart()"
  (changeImg)="changeImg()"
  (setFirstImg)="setFirstImg()"
  (removeClicked)="removeClicked.emit()"
>
</lib-restaurants-item-wide-card>

<ng-template #keepRemoveCartModalRef>
  <div class="modal-content">
    <!--body-->
    <div class="modal-body">
      <div class="row p-2 justify-content-center">
        {{cartErrorMessage}}
      </div>
    </div>
    <div class="modal-footer modal-footer-center" style="display: flex;">
      <button type="submit" [style.background]="'white'" [style.color]="storeDesignOptions.color" type="submit"
        class="footer-btn-new" (click)="closeKeepRemoveCartModal()">{{translations?.ContinueShopping}}</button>
      <button type="submit" [style.background]="'white'" [style.color]="'red'" type="submit" class="footer-btn-new"
        (click)="resetCart()">
        <div *ngIf="!resetLoading">
          {{translations?.Reset}}
        </div>
        <label style="cursor: pointer;" [ngClass]="resetLoading ? 'btn-spinner' : '' "
          [style.border-color]="desginOptions?.frontColor">
        </label>
      </button>
    </div>
  </div>
</ng-template>

<!-- [itemQuantity]="storeService.getItemQuantity(item?.id)" -->
<!-- [loadingRemove]="orderAppService.loadingRemove && orderAppService.removedItemId === cartLineId" -->