<ng-container *ngIf="!isModal">
    <ng-content *ngTemplateOutlet="mainContent"></ng-content>
  </ng-container>
  
  <ng-container *ngIf="isModal">
    <div class="modal-content">
      <!--header-->
      <div class="modal-header">
        <button type="button" [class]="isRtl ? 'close close-left' : 'close'" (click)="close()">&times;</button>
        <h3 class="modal-title">{{translations?.Address}}</h3>
      </div>
      <!--body-->
      <div class="modal-body">
        <ng-content *ngTemplateOutlet="mainContent"></ng-content>
      </div>
      <div class="modal-footer modal-footer-center">
        <button type="submit" [style.background]="storeDesignOptions?.color" [style.color]="storeDesignOptions?.frontColor" type="submit" class="footer-btn" (click)="input()">{{translations?.Save}}</button>
    </div>
    </div>
  </ng-container>
  
  <ng-template #mainContent>
      <lib-default-address-form
      [desginOptions]="desginOptions"
      [configuration]="configuration"
      [countryListItems]="countryListItems"
      [translations]="translations"
      [countryName]="countryName"
      [address]="address"
      [submited]="submited"
      [fieldOptions]="fieldOptions"
      [stateListItems]="stateListItems"
      [cityListItems]="cityListItems"
      [districtListItems]="districtListItems"
      [viewLabel]="viewLabel"
      [loadingCountry]="loadingCountry"
      [loadingState]="loadingState"
      [loadingCity]="loadingCity"
      [loadingDistrict]="loadingDistrict"
      (resetAddressPoS)="resetAddressPosition()"
      (selectCountry)="onSelectCountry()"
      (selectState)="onSelectedState()"
      (selectCity)="onSelectedCity()"
      (selectDistrict)="onSelectedDistrict()"
      (updateAddress)="setAddress()"
      (showGoogleMap)="showGoogleMapModel()"
    >
    </lib-default-address-form>
  </ng-template>
  