import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PriceRange, ScreenBreakpointsService } from '@storefront/ng.core';
import {
  CartService,
  CartStoreDto,
  CustomerFavoriteItemService,
  ItemDetailStoreDto,
  ItemStoreDto,
  ItemStoreList,
  ItemStoreMarketplaceList,
  NoStockStatus,
} from '@tajer/api';
import { Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import {
  IonicItemWideCardConfiguration,
  IonicItemWideCardDesignOptions,
  MerchItemWideCardConfiguration,
  MerchItemWideCardDesignOptions,
  RestaurantsItemWideCardConfiguration,
  RestaurantsItemWideCardDesignOptions,
} from '@storefront/ng.themes';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CartAppService } from 'src/app/shared/app-services/cart-app.service';
import { FavoriteAppService } from 'src/app/shared/app-services/favorite-app.service';
import { OutletsAppService } from 'src/app/shared/app-services/outlets-app.service';
import { ItemsAppService } from 'src/app/shared/app-services/items-app.service';
import { PluginRegistryService } from 'src/app/plugins/core';
import { CookieAppService } from 'src/app/shared/app-services/cookie-app.service';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { DialogRef, overlayConfigFactory } from 'ngx-modialog-7';

@Component({
  selector: 'app-item-wide-card',
  templateUrl: './item-wide-card.component.html',
  styleUrls: ['./item-wide-card.component.scss'],
})
export class ItemWideCardComponent extends BaseComponent {
  //#region variables
  @Input() item: ItemStoreList | ItemStoreMarketplaceList;
  //for cart / order
  @Input() itemStore: ItemStoreDto;
  @Input() isEdit: boolean;
  @Input() isView: boolean;
  @Input() showVariant: boolean;
  @Input() quantity?: number;
  @Input() price?: number;
  @Input() oldPriceUpdated?: number;
  @Input() total?: number;
  @Input() description: string;
  @Input() itemDetailId: string; //needed for card
  @Input() modifiers: any[] = [];
  @Input() cartLineId: string;

  itemDetail: ItemDetailStoreDto;

  quantityChanges$ = new Subject<number>();
  @Output() quantityChanged = new EventEmitter();
  @Output() removeClicked = new EventEmitter();
  addingToCart: boolean;
  added: boolean;
  /**
   * need this params in side menu cart
   * viewAddToCart param: to view add to cart an favorite button
   * viewQuantity param: if true to view quantity of item always
   * viewRemoveBtn param: if true to only view remove button
   */
  @Input() viewAddToCart: boolean = true;
  @Input() viewQuantity: boolean;
  @Input() viewRemoveBtn: boolean;
  public get variants(): any[] {
    const variants = [];
    if (!this.itemStore || !this.itemDetail) {
      return variants;
    }
    if (
      this.itemStore.variantOption1Name &&
      this.itemDetail.variantOption1Value
    ) {
      variants.push({
        name: this.getValue(this.itemStore, 'variantOption1Name'),
        value: this.itemDetail.variantOption1Value,
      });
    }
    if (
      this.itemStore.variantOption2Name &&
      this.itemDetail.variantOption2Value
    ) {
      variants.push({
        name: this.getValue(this.itemStore, 'variantOption2Name'),
        value: this.itemDetail.variantOption2Value,
      });
    }
    if (
      this.itemStore.variantOption3Name &&
      this.itemDetail.variantOption3Value
    ) {
      variants.push({
        name: this.getValue(this.itemStore, 'variantOption3Name'),
        value: this.itemDetail.variantOption3Value,
      });
    }
    if (
      this.itemStore.variantOption4Name &&
      this.itemDetail.variantOption4Value
    ) {
      variants.push({
        name: this.getValue(this.itemStore, 'variantOption4Name'),
        value: this.itemDetail.variantOption4Value,
      });
    }
    return variants;
  }

  @Input() isFavorite: boolean;
  @Input() favoriteItemId: string;
  @Output() refreshFavoriteList = new EventEmitter<any>();
  public get priceColor(): string {
    return (
      this.storeDesignOptions?.itemPriceColor ?? this.storeDesignOptions?.color
    );
  }
  images = [];
  priceRange: PriceRange;
  @Input() isSideMenuCart: boolean;

  //added new
  desginOptions: IonicItemWideCardDesignOptions;
  configuration: IonicItemWideCardConfiguration;

  //merch theme
  merchDesginOptions: MerchItemWideCardDesignOptions;
  merchConfiguration: MerchItemWideCardConfiguration;

  //Restaurants theme
  restaurantsDesginOptions: RestaurantsItemWideCardDesignOptions;
  restaurantsConfiguration: RestaurantsItemWideCardConfiguration;
  limit = 35;
  @Input() hideDefaultItemImage: boolean;

  //keepRemoveCartModal
  @ViewChild('keepRemoveCartModalRef', { static: false })
  keepRemoveCartModalRef: TemplateRef<any>;
  dialogRef: DialogRef<any>;
  cartErrorMessage: string;
  resetLoading: boolean;
  @Input() itemTenantId: string;
  //#endregion

  constructor(
    injector: Injector,
    private cartService: CartService,
    public favoriteAppService: FavoriteAppService,
    public outletsAppService: OutletsAppService,
    private favoriteService: CustomerFavoriteItemService,
    private itemsAppService: ItemsAppService,
    public screenBreakpointsService: ScreenBreakpointsService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.itemStore) {
      if (this.itemDetailId) {
        this.itemDetail = this.itemStore.itemDetails.find(
          (details) => details.id === this.itemDetailId
        );
      } else {
        this.itemDetail = this.itemStore.itemDetails[0];
      }

      if (this.itemStore.images) {
        this.populateImages();
      }

      //assign item and itemDetail to item
      this.item = {
        id: this.itemStore.id,
        name: this.getValue(this.itemStore),
        calories: this.itemDetail.calories,
        featured: this.itemStore.featured,
        hasVariants: this.isItemHasMultiVariants(this.itemStore.itemDetails),
        postTaxPrice: this.itemDetail.postTaxPrice,
        preTaxPrice: this.itemDetail.preTaxPrice,
        hasModifierLists: this.itemDetail.hasModifierLists,
        hasOffers: this.itemDetail.hasOffers,
        price: this.itemDetail.price,
        oldPrice: this.itemDetail.oldPrice,
        sku: this.itemDetail.sku,
        slug: this.getValue(this.itemStore, 'slug'),
        thumbnail: this.itemStore.thumbnail,
        shortDescription: this.getValue(this.itemStore, 'shortDescription'),
        isOutOfStock: this.isOutOfStock,
        itemDetailId: this.itemDetail.id,
        minPrice: this.getMinPriceForItem(this.itemStore.itemDetails),
        maxPrice: this.getMaxPriceForItem(this.itemStore.itemDetails),
        tenantId: this.itemTenantId,
      } as ItemStoreList;
    }

    if (this.item) {
      this.pluginRegistry.trigger(
        'implementViewProductEvent',
        this.item,
        this.getValue(this.item),
        this.itemsAppService.source
      );

      //populated later
      // if (this.item.images) {
      //   this.populateImages();
      // }
      //if item has multi variants
      if (this.item.hasVariants) {
        this.priceRange = this.getPriceRangeForItem();
      }
    }
    this.subscribeQuantityChanged(this.quantityChanges$);

    if (this.currentTheme == 'ionic' || this.currentTheme == 'customizedIonic') {
      this.setIonicThemeOptions();
    } else if (this.currentTheme == 'merch' || this.currentTheme == 'shopper') {
      this.setMerchThemeOptions();
    } else if (this.currentTheme == 'restaurants') {
      this.setRestaurantsThemeOptions();
    }
  }

  setIonicThemeOptions() {
    this.desginOptions = {
      priceColor: this.priceColor,
      showFeaturedTagOnItemCard:
        this.storeDesignOptions?.showFeaturedTagOnItemCard,
      showOfferTagOnItemCard: this.storeDesignOptions?.showOfferTagOnItemCard,
      showItemSkuOnItem: this.storeDesignOptions?.showItemSkuOnItem,
      showDescriptionOnItemWideCard:
        this.storeDesignOptions?.showDescriptionOnItemWideCard,
      showCartQuantityOnItemCard:
        this.storeDesignOptions?.showCartQuantityOnItemCard,
      showItemPreTaxPrice: this.storeOptions.showItemPreTaxPrice,
      showItemPostTaxPrice: this.storeOptions.showItemPostTaxPrice,
      hidePrices: this.storeOptions.hidePrices,
      showMultiVariantsPriceFromOnItemCard:
        this.storeDesignOptions?.showMultiVariantsPriceFromOnItemCard,
      showMultiVariantsPriceFromToOnItemCard:
        this.storeDesignOptions?.showMultiVariantsPriceFromToOnItemCard,
      showCalories: this.storeOptions.showCalories,
      showCaloriesOnItemCard: this.storeDesignOptions?.showCaloriesOnItemCard,
      isFavoriteItemsEnabled: this.storeOptions.isFavoriteItemsEnabled,
      changeAddToCartToSelectOption:
        this.storeOptions.changeAddToCartToSelectOption,
      showAddToCartOnItemCard: this.storeDesignOptions?.showAddToCartOnItemCard,
      disableAddToCartOnItem: this.storeOptions.disableAddToCartOnItem,
      color: this.storeDesignOptions?.color,
      displayBriefItemTitle:
        this.storeDesignOptions?.displayBriefItemTitleOnItemCard,
    };

    this.configuration = {
      itemUrl: !this.isMarketplace
        ? this.getUrl('/catalog/' + this.item?.slug)
        : this.getUrl(
            '/catalog/' + this.item?.slug + '/' + this.item?.tenantId
          ),
      defaultImage: this.defaultImage,
    };
  }

  setMerchThemeOptions() {
    this.merchDesginOptions = {
      priceColor: this.priceColor,
      showFeaturedTagOnItemCard:
        this.storeDesignOptions?.showFeaturedTagOnItemCard,
      showOfferTagOnItemCard: this.storeDesignOptions?.showOfferTagOnItemCard,
      showItemSkuOnItem: this.storeDesignOptions?.showItemSkuOnItem,
      showDescriptionOnItemWideCard:
        this.storeDesignOptions?.showDescriptionOnItemWideCard,
      showCartQuantityOnItemCard:
        this.storeDesignOptions?.showCartQuantityOnItemCard,
      showItemPreTaxPrice: this.storeOptions.showItemPreTaxPrice,
      showItemPostTaxPrice: this.storeOptions.showItemPostTaxPrice,
      hidePrices: this.storeOptions.hidePrices,
      showMultiVariantsPriceFromOnItemCard:
        this.storeDesignOptions?.showMultiVariantsPriceFromOnItemCard,
      showMultiVariantsPriceFromToOnItemCard:
        this.storeDesignOptions?.showMultiVariantsPriceFromToOnItemCard,
      showCalories: this.storeOptions.showCalories,
      showCaloriesOnItemCard: this.storeDesignOptions?.showCaloriesOnItemCard,
      isFavoriteItemsEnabled: this.storeOptions.isFavoriteItemsEnabled,
      changeAddToCartToSelectOption:
        this.storeOptions.changeAddToCartToSelectOption,
      showAddToCartOnItemCard: this.storeDesignOptions?.showAddToCartOnItemCard,
      disableAddToCartOnItem: this.storeOptions.disableAddToCartOnItem,
      color: this.storeDesignOptions?.color,
      displayBriefItemTitle:
        this.storeDesignOptions?.displayBriefItemTitleOnItemCard,
    };

    this.merchConfiguration = {
      itemUrl: !this.isMarketplace
        ? this.getUrl('/catalog/' + this.item?.slug)
        : this.getUrl(
            '/catalog/' + this.item?.slug + '/' + this.item?.tenantId
          ),
      defaultImage: this.defaultImage,
    };
  }

  setRestaurantsThemeOptions() {
    this.restaurantsDesginOptions = {
      priceColor: this.priceColor,
      showFeaturedTagOnItemCard:
        this.storeDesignOptions?.showFeaturedTagOnItemCard,
      showOfferTagOnItemCard: this.storeDesignOptions?.showOfferTagOnItemCard,
      showItemSkuOnItem: this.storeDesignOptions?.showItemSkuOnItem,
      showDescriptionOnItemWideCard:
        this.storeDesignOptions?.showDescriptionOnItemWideCard,
      showCartQuantityOnItemCard:
        this.storeDesignOptions?.showCartQuantityOnItemCard,
      showItemPreTaxPrice: this.storeOptions.showItemPreTaxPrice,
      showItemPostTaxPrice: this.storeOptions.showItemPostTaxPrice,
      hidePrices: this.storeOptions.hidePrices,
      showMultiVariantsPriceFromOnItemCard:
        this.storeDesignOptions?.showMultiVariantsPriceFromOnItemCard,
      showMultiVariantsPriceFromToOnItemCard:
        this.storeDesignOptions?.showMultiVariantsPriceFromToOnItemCard,
      showCalories: this.storeOptions.showCalories,
      showCaloriesOnItemCard: this.storeDesignOptions?.showCaloriesOnItemCard,
      isFavoriteItemsEnabled: this.storeOptions.isFavoriteItemsEnabled,
      changeAddToCartToSelectOption:
        this.storeOptions.changeAddToCartToSelectOption,
      showAddToCartOnItemCard: this.storeDesignOptions?.showAddToCartOnItemCard,
      disableAddToCartOnItem: this.storeOptions.disableAddToCartOnItem,
      color: this.storeDesignOptions?.color,
      displayBriefItemTitle:
        this.storeDesignOptions?.displayBriefItemTitleOnItemCard,
    };

    this.restaurantsConfiguration = {
      itemUrl: !this.isMarketplace
        ? this.getUrl('/catalog/' + this.item?.slug)
        : this.getUrl(
            '/catalog/' + this.item?.slug + '/' + this.item?.tenantId
          ),
      defaultImage: this.defaultImage,
    };
  }

  increaseQuantity() {
    this.orderAppService.increaseQuantityLoading = true;
    this.orderAppService.increaseQuantityItemId = this.cartLineId;
    if (this.quantity < 99) {
      this.quantityChanges$.next(++this.quantity);
    }
  }

  decreaseQuantity() {
    this.orderAppService.decreaseQuantityLoading = true;
    this.orderAppService.decreaseQuantityItemId = this.cartLineId;
    if (this.quantity > 0) {
      this.quantityChanges$.next(--this.quantity);
    }
  }

  subscribeQuantityChanged(quantityChanges: Observable<number>) {
    quantityChanges
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((quantity: any) => {
        if (Number.parseInt(quantity) >= 0) {
          this.quantity = quantity;
          this.quantityChanged.emit(quantity);
        }
      });
  }

  onUpdateQuantity(quantity: any) {
    this.quantityChanges$.next(quantity);
  }

  addToCart() {
    if (
      this.storeOptions.changeAddToCartToSelectOption &&
      this.item?.hasVariants
    ) {
      this.router.navigate([this.getUrl('/catalog/' + this.item.slug)]);
      return;
    }
    if (
      this.outletsAppService.openSelectOutletWhenAddingToCart(
        this.getValue(this.item, 'slug')
      )
    ) {
      return;
    }
    this.addingToCart = true;
    this.cartService[this.isMarketplace ? 'addMarketplace' : 'addItem'](
      this.item.itemDetailId,
      {
        quantity: 1,
        token: this.storeAppService.storeConfig.token,
        outletId: this.isMarketplace
          ? this.getMarketplaceCartOutletId(
              (this.item as ItemStoreMarketplaceList).tenantId
            )
          : this.currentOutlet.id,
        cartLineModifiers: [],
        tenantId: this.isMarketplace
          ? (this.item as ItemStoreMarketplaceList).tenantId
          : null,
        currencyId: this.requestCurrencyId,
      }
    )
      .toPromise()
      .then(
        (response) => {
          if (this.isMarketplace) {
            this.updateMarketTenantId(this.cartAppService.shoppingCart);
          }
          this.cartAppService.shoppingCart = response.cart;
          if (this.storeOptions.autoOpenCartWhenAddItemToCart) {
            this.openCartMenu();
          }
          this.addingToCart = false;
          this.added = true;
          this.showAdded();

          this.pluginRegistry.trigger(
            'implementAddToCartEvent',
            this.item,
            this.item.name,
            1,
            this.itemsAppService.source
          );

          if (this.isSegmentEnabled) {
            this.segmentService.implementAddToCartEvent(
              this.item,
              this.item.name,
              1
            );
          }
          if (this.isSegmentEnabled && this.isFavorite) {
            this.segmentService.implementMoveProductFromWishlistToCartEvent(
              this.item,
              this.item.name,
              1
            );
          }
        },
        async (response) => {
          this.addingToCart = false;
          if (this.isMarketplace) {
            this.cartErrorMessage = response?.error?.error
              ? response?.error?.error.message
              : '';
            this.openKeepRemoveCartModal();
          } else {
            this.showError(response);
          }
        }
      );
  }

  addOrRemoveFavorite() {
    if (this.customerAppService.hasLoggedIn) {
      if (this.favoriteItemId) {
        this.removeFromFavorite();
      } else if (!this.isFavorite) {
        this.addToFavorite();
      } else if (this.isFavorite) {
        this.removeFromFavorite();
      }
    } else {
      this.openAddToFavoriteAlert();
    }
  }

  addToFavorite() {
    this.favoriteAppService.addOrRemoveFavoriteItemId = this.item.id;
    this.favoriteAppService.addOrRemoveFavoriteLoading = true;
    //if is mareketplace then we call add to faviorate based on marketplace api
    let addToFaviorateObservable = null;
    if (this.isMarketplace) {
      addToFaviorateObservable = this.favoriteService.createMarketplace({
        itemId: this.item.id,
        tenantId: this.item?.tenantId,
      });
    } else {
      addToFaviorateObservable = this.favoriteService.create({
        itemId: this.item.id,
      });
    }
    addToFaviorateObservable.toPromise().then(
      (response) => {
        this.favoriteAppService.addOrRemoveFavoriteLoading = false;
        this.favoriteAppService.addOrRemoveFavoriteItemId = null;
        this.isFavorite = true;
        this.refreshFavoriteList.emit();
        this.pluginRegistry.trigger(
          'implementAddToWishlistEvent',
          this.item,
          this.item.name,
          1
        );

        if (this.isSegmentEnabled) {
          this.segmentService.implementAddToWishlistEvent(
            this.item,
            this.item.name
          );
        }
      },
      async (response) => {
        this.favoriteAppService.addOrRemoveFavoriteLoading = false;
        this.favoriteAppService.addOrRemoveFavoriteItemId = null;
        this.showError(response);
      }
    );
  }

  removeFromFavorite() {
    this.favoriteAppService.addOrRemoveFavoriteItemId = this.item.id;
    this.favoriteAppService.addOrRemoveFavoriteLoading = true;
    //if is mareketplace then we call remove from faviorate based on marketplace api
    let removeFromFaviorateObservable = null;
    if (this.isMarketplace) {
      removeFromFaviorateObservable = this.favoriteService.deleteMarketplace({
        itemId: this.item.id,
        tenantId: this.item?.tenantId,
      });
    } else {
      removeFromFaviorateObservable = this.favoriteService.delete(this.item.id);
    }
    removeFromFaviorateObservable.toPromise().then(
      (response) => {
        this.favoriteAppService.addOrRemoveFavoriteLoading = false;
        this.favoriteAppService.addOrRemoveFavoriteItemId = null;
        this.isFavorite = false;
        this.refreshFavoriteList.emit();
        if (this.isSegmentEnabled) {
          this.segmentService.implementRemoveFromWishlistEvent(
            this.item,
            this.item.name
          );
        }
      },
      async (response) => {
        this.favoriteAppService.addOrRemoveFavoriteLoading = false;
        this.favoriteAppService.addOrRemoveFavoriteItemId = null;
        this.showError(response);
      }
    );
  }

  private showAdded() {
    setTimeout(() => {
      this.added = false;
    }, 2500);
  }

  addEvents() {
    this.pluginRegistry.trigger(
      'implementClickOnProductEvent',
      this.item,
      this.itemsAppService.source
    );

    if (this.isSegmentEnabled) {
      this.segmentService.implementClickOnProductEvent(this.item);
    }
  }

  changeImg() {
    if (
      this.images.length > 1 &&
      this.storeDesignOptions?.changeItemImageWhenHover
    ) {
      this.item.thumbnail = this.images[1];
    }
  }

  setFirstImg() {
    if (
      this.images.length > 1 &&
      this.storeDesignOptions?.changeItemImageWhenHover
    ) {
      this.item.thumbnail = this.images[0];
    }
  }

  private populateImages() {
    this.images = this.itemStore.images.split(',');
  }

  openCartMenu() {
    this.orderAppService.openCart();
    this.cartAppService.sideMenuCartOpened = true;
  }

  isItemHasMultiVariants(itemDetails: ItemDetailStoreDto[]): boolean {
    return itemDetails?.length > 1;
  }

  private get isOutOfStock(): boolean {
    return (
      this.itemStore.stockTracked &&
      this.itemDetail.quantity <= 0 &&
      this.itemDetail.noStockStatus === NoStockStatus.Unavailable
    );
  }

  getMinPriceForItem(itemDetails: ItemDetailStoreDto[]): number {
    var priceArray: number[] = [];
    let min = 0;
    itemDetails?.forEach((row) => priceArray.push(row.price));
    min = Math.min(...priceArray);
    return min;
  }

  getMaxPriceForItem(itemDetails: ItemDetailStoreDto[]): number {
    var priceArray: number[] = [];
    let max = 0;
    itemDetails?.forEach((row) => priceArray.push(row.price));
    max = Math.max(...priceArray);
    return max;
  }

  getPriceRangeForItem(): PriceRange {
    var range: PriceRange = { min: 0, max: 0 };
    range.min = this.item.minPrice ? this.item.minPrice : 0;
    range.max = this.item.maxPrice ? this.item.minPrice : 0;
    return range;
  }

  /**
   * in case we are in a marketplace and current cart is empty then
   * we change the current market tenant to the item store tenant id
   * and make it the store for the cart
   */
  private updateMarketTenantId(cart: CartStoreDto) {
    if (!cart || !cart.cartLines || cart.cartLines.length == 0) {
      this.cookieAppService.SetMarketTenantId(
        (this.item as ItemStoreMarketplaceList).tenantId
      );
      //change outlet to the selected tenant outlet
      this.outletsAppService.updateStoreOutlet(
        this.getMarketplaceCartOutletId(
          (this.item as ItemStoreMarketplaceList).tenantId
        )
      );
    }
  }

  //openKeepRemoveCartModal
  openKeepRemoveCartModal() {
    const componentContext = <BSModalContext>{
      inElement: true,
      dialogClass: 'modal-x-sm',
      isBlocking: false,
    };

    this.dialogRef = this.modal.open(
      this.keepRemoveCartModalRef,
      overlayConfigFactory(componentContext)
    );

    this.dialogRef.onDestroy.subscribe(() => {
      if (this.isBrowser) {
        document.body.classList.remove('modal-open');
      }
    });

    this.dialogRef.result.then(
      (result) => {
        if (result) {
        }
      },
      () => {
        return;
      }
    );
  }

  closeKeepRemoveCartModal() {
    this.dialogRef?.close();
  }

  resetCart() {
    this.resetLoading = true;
    this.cartService
      .deleteMarketplace({
        tenantId: this.cookieAppService.getMarketTenantId(),
        token: this.storeAppService.storeConfig?.token,
        currencyId: this.requestCurrencyId,
      })
      .toPromise()
      .then(
        (response) => {
          this.cartAppService.shoppingCart = null;
          this.cookieAppService.SetMarketTenantId(
            this.storeAppService.storeConfig?.tenantId
          );
          //change outlet to the selected tenant outlet
          this.outletsAppService.updateStoreOutlet(
            this.getMarketplaceCartOutletId(
              this.storeAppService.storeConfig?.tenantId
            )
          );
          this.resetLoading = false;
          this.closeKeepRemoveCartModal();
        },
        (error) => {
          console.log('error');
          console.log(error);
          this.resetLoading = false;
          this.closeKeepRemoveCartModal();
        }
      );
  }

  //item-wide-card-new
  public get itemShortDescription(): string {
    if (
      this.storeDesignOptions?.forceHeightOnItemWideCard &&
      this.item?.shortDescription?.length > this.limit
    ) {
      return this.item?.shortDescription.substring(0, this.limit) + '...';
    } else {
      return this.item?.shortDescription;
    }
  }

  public get cardHeight(): string {
    var height: string;
    if (this.storeDesignOptions?.forceHeightOnItemWideCard) {
      if (this.currentTheme == 'ionic' || this.currentTheme == 'customizedIonic') {
        if (this.isEdit) {
          height = '200px';
        } else if (
          ((this.showVariant && this.variants.length > 0) ||
            this.modifiers.length > 0) &&
          !this.isEdit
        ) {
          height = '240px';
        } else {
          height = '195px';
        }
      } else if (
        this.currentTheme == 'merch' ||
        this.currentTheme == 'shopper'
      ) {
        if (
          (this.showVariant && this.variants.length > 0) ||
          this.modifiers.length > 0
        ) {
          height = '270px';
        } else {
          height = '240px';
        }
      } else if (this.currentTheme == 'restaurants') {
        if (
          !(
            (this.showVariant && this.variants.length > 0) ||
            this.modifiers.length > 0
          ) &&
          !this.screenBreakpointsService.isSizeSmOrDown
        ) {
          height = '210px';
        } else {
          height = '230px';
        }
      }
    } else {
      height = 'auto';
    }
    return height;
  }

  public get offerPercentage(){
    if(this.item?.oldPrice && this.storeDesignOptions?.showOfferPercentageOnItemCard){
      let percent= ((this.item.oldPrice-this.item.price)/this.item.oldPrice)*100;
      return Number(percent.toFixed(2));
    }
    return 0;
  }

}
