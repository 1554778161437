<storefront-ionic-footer *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'" [desginOptions]="desginOptions" [configuration]="configuration"
    [translations]="translations" [outletLocationLink]="addressAppService.getOutletLocationLink(currentOutlet)"
    [outletAddressSummary]="addressAppService.getOutletAddressSummary(currentOutlet)" [footerPages]="footerPages"
    [footerBlogPages]="footerBlogPages" [categories]="footerCategories" [providerWebsite]="providerWebsite"
    [providerName]="providerName" [paymentTypes]="paymentTypes" [hasPaymentCards]="this.hasPaymentCards()"
    [showOutletAddressInFooter]="addressAppService.getOutletAddressSummary(currentOutlet) && storeDesignOptions?.showOutletAddressInFooter"
    (addFacebookPixelContactEvent)="addFacebookPixelContactEvent()">
    <newsletter>
        <app-subscribe-form [headColor]="footerHeadingColor" [frontColor]="frontColor"
            [showAsGrid]="false"></app-subscribe-form>
    </newsletter>
</storefront-ionic-footer>

<!-- [isSizeLgOrUp]="utility.isSizeLgOrUp" -->
<!-- [footerCategories]="utility.storeAppService.footerCategories" -->
<!-- [storeName]="getValue(storeConfig)" -->
<!-- [storeAbout]="getValue(storeConfig, 'about')" -->
<!-- [showOutletAddressInFooter]="getOutletAddressSummary(currentOutlet) && templateConfig.showOutletAddressInFooter" -->
<!-- [outletLocationLink]="getOutletLocationLink(currentOutlet)" -->
<!-- [mobilePhoneCallLink]="utility.getCallLink(storeConfig.mobilePhone)" -->
<!-- [whatsAppLink]="utility.getWhatsAppLink(storeConfig.whatsApp)" -->
<!-- [telegramLink]="utility.getTelegramLink(storeConfig.telegram)" -->
<!-- [isStoreBlogEnabled]="storeConfig.options.isStoreBlogEnabled" -->
<!-- [storeBlogRouterLink]="getUrl('/blog')" -->
<!-- [showOurOutletsInFooter]="storeService.isMultiOutletEnabled && templateConfig.showOurOutletsInFooter" -->
<!-- [outletsRouterLink]="getUrl('/outlets')" -->
<!-- [footerPages]="storeService.footerPages" -->
<!-- [footerBlogPages]="storeService.footerBlogPages" -->
<!-- [showCategoryListInFooter]="templateConfig.showCategoryListInFooter" -->
<!-- [footerCategories]="utility.storeAppService.footerCategories" -->
<!-- [paymentTypes]="storeService.paymentTypes" -->

<!-- ====== Merch Footer Section Start -->
<lib-merch-footer *ngIf="currentTheme == 'merch'" [merchDesginOptions]="merchDesginOptions"
    [merchConfiguration]="merchConfiguration" [currentLang]="currentLang" [translations]="translations"
    [categories]="footerCategories" [outletLocationLink]="addressAppService.getOutletLocationLink(currentOutlet)"
    [outletAddressSummary]="addressAppService.getOutletAddressSummary(currentOutlet)" [footerPages]="footerPages"
    [footerBlogPages]="footerBlogPages" (addFacebookPixelContact)="addFacebookPixelContactEvent()"
    [providerWebsite]="providerWebsite" [providerName]="providerName" [paymentTypes]="paymentTypes"
    [hasPaymentCards]="this.hasPaymentCards()"
    [showOutletAddressInFooter]="addressAppService.getOutletAddressSummary(currentOutlet) && storeDesignOptions?.showOutletAddressInFooter">

    <newsletter>
        <app-subscribe-form [headColor]="footerHeadingColor" [frontColor]="color"></app-subscribe-form>
    </newsletter>
</lib-merch-footer>

<!-- ====== Shopper Footer Section Start -->
<lib-shopper-footer *ngIf="currentTheme == 'shopper'" [shopperDesginOptions]="shopperDesginOptions"
    [shopperConfiguration]="shopperConfiguration" [translations]="translations" [categories]="footerCategories"
    [outletLocationLink]="addressAppService.getOutletLocationLink(currentOutlet)"
    [outletAddressSummary]="addressAppService.getOutletAddressSummary(currentOutlet)" [footerPages]="footerPages"
    [footerBlogPages]="footerBlogPages" (addFacebookPixelContact)="addFacebookPixelContactEvent()"
    [providerWebsite]="providerWebsite" [providerName]="providerName" [paymentTypes]="paymentTypes"
    [hasPaymentCards]="this.hasPaymentCards()" [currentLang]="currentLang" [isRtl]="isRtl"
    [showOutletAddressInFooter]="addressAppService.getOutletAddressSummary(currentOutlet) && storeDesignOptions?.showOutletAddressInFooter">
    <newsletter>
        <app-subscribe-form [headColor]="footerHeadingColor"></app-subscribe-form>
    </newsletter>
</lib-shopper-footer>

<!-- ====== Restaurants Footer Section Start -->
<lib-restaurants-footer *ngIf="currentTheme == 'restaurants'"
    [restaurantsDesginOptions]="restaurantsDesginOptions"
    [restaurantsConfiguration]="restaurantsConfiguration"
    [translations]="translations"
    [categories]="footerCategories"
    [outletLocationLink]="addressAppService.getOutletLocationLink(currentOutlet)"
    [outletAddressSummary]="addressAppService.getOutletAddressSummary(currentOutlet)"
    [footerPages]="footerPages"
    [footerBlogPages]="footerBlogPages"
    (addFacebookPixelContact)="addFacebookPixelContactEvent()"
    [providerWebsite]="providerWebsite"
    [providerName]="providerName"
    [paymentTypes]="paymentTypes"
    [hasPaymentCards]="this.hasPaymentCards()"
    [currentLang]="currentLang"
    [isRtl]="isRtl"
    [showOutletAddressInFooter]="addressAppService.getOutletAddressSummary(currentOutlet) && storeDesignOptions?.showOutletAddressInFooter"
>
    <newsletter>
        <app-subscribe-form [headColor]="footerHeadingColor"
            [footerFrontColor]="restaurantsDesginOptions?.footerFrontColor"></app-subscribe-form>
    </newsletter>
</lib-restaurants-footer>